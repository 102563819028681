<template>
  <div class="panelbox">
    <LeftPannel />
    <div class="right-panel staff-section-page">
      <div class="header">
        <div class="left-title">
          <h1>Task</h1>
        </div>

        <a class="staff-timer-div" @click="timerstarting" v-if="startTracker" v-bind:style="{
          padding: '4px 0',
          'text-decoration': 'none',
          color: 'inherit',
        }">
          <p>
            <span class="staf-timing">
              <a v-bind:style="{ 'text-decoration': 'none', color: 'inherit' }">
                <img src="../../../assets/images/timer.png" />Tracker
              </a>
            </span>
          </p>
        </a>
        <a class="staff-timer-div" @click="stopTracking" v-if="stopTracker" v-bind:style="{
          padding: '4px 0',
          'text-decoration': 'none',
          color: 'inherit',
        }">
          <p>
            <span class="staf-timing">
              <a>
                <img src="../../../assets/images/timer.png" />{{
          formattedElapsedTime
        }}
              </a>
            </span>
          </p>
        </a>
        <div class="staff-checkinout-div">
          <label class="round_ckbox" for="staff_check_in">
            <input type="checkbox" id="staff_check_in" v-model="data.is_checkin" @change="checkIn" value="checkin" />
            <span class="checkmark"></span>Check In
          </label>
          <label class="round_ckbox" for="staff_check_out">
            <input type="checkbox" id="staff_check_out" v-model="data.is_checkout" @change="checkOut"
              value="checkout" />
            <span class="checkmark"></span>Check Out
          </label>
        </div>
        <startTracking v-if="showstartTracker">
          <div id="popup1" class="popup-container">
            <div class="popup-content">
              <a href="#" class="close" @click="closepopup">&times;</a>
              <div class="start-tracking-body" id="">
                <h3>Start Tracking</h3>
                <div class="start-track-form">
                  <form @submit.prevent>
                    <select name="Select_Project" id="Select-Project" v-model="projectid">
                      <option value="" selected>Project Name</option>
                      <option v-for="data in projectnames" :value="data.id" :key="data.id">
                        {{ data.project_name }}
                      </option>
                    </select>
                    <p></p>
                    <textarea name="Task" rows="3" placeholder="Task" v-model="task"></textarea>
                    <p></p>
                    <input v-if="this.task && this.projectid" type="submit" value="Start" name="start_button"
                      @click.once="start" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </startTracking>
        <stopTracking v-if="showstopTracker">
          <div id="popup2" class="popup-container popup-style-2">
            <div class="popup-content">
              <a href="#" class="close">&times;</a>
              <div class="start-tracking-body" id="">
                <h3>Stop Tracking</h3>
                <div class="start-track-form">
                  <form @submit.prevent>
                    <select name="occa-design" id="OCCA-Design" v-model="projectid">
                      <option v-for="data in projectnames" :value="data.id" :key="data.id">
                        {{ data.project_name }}
                      </option>
                    </select>
                    <p></p>
                    <textarea name="Task" rows="3" v-model="task" v-bind:style="{ 'margin-top': '20px' }"></textarea>
                    <p></p>
                    <h2 class="popup-total-time">
                      Time: {{ formattedElapsedTime }}
                    </h2>
                    <p></p>
                    <input v-if="this.task && this.projectid" type="submit" value="Stop" name="start_button"
                      @click.once="stop" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </stopTracking>
        <div class="right-admindetail">
          <p>
            <span>{{ matches.toUpperCase() }}</span>
          </p>
        </div>
      </div>
      <div class="loaderbox" v-if="this.loading">
        <img style="width: 100px" src="../../../assets/images/Loading-bar.gif" alt="&laquo;" />
      </div>
      <div class="content-page">
        <div class="row admin-staff-list-toppart">
          <div class="col-md-4">
            <input class="search-leave satff-listing-page-search" type="text" name="search" v-model="filter.name"
              placeholder="Search Task / Description" />
          </div>
          <div class="col-md-4">
            <input type="submit" value="Submit" class="yellow-button" @click="filterSearch" />
            <input type="submit" value="Clear" class="yellow-button" v-if="filter.is_filter == 1" @click="showTask" />
          </div>
          <div class="col-md-4">
            <div class="addnew-button">
              <router-link :to="{ name: 'StaffAddTask' }">
                <span><img src="../../../assets/images/plus-icon.png" alt="Add Task" /></span>Add New
              </router-link>
            </div>
          </div>
        </div>
        <div class="track-top-filter-form staff-date common-white-shadow-background">
          <div class="trach-filter-form-field">
            <select name="project" v-model="filter.project" @change="filterSearch($event)">
              <option value="" selected>All Projects</option>
              <option v-for="data in projectnames" :value="data.id" :key="data.id">
                {{ data.project_name }}
              </option>
            </select>
          </div>
          <div class="trach-filter-form-field">
            <input type="date" v-model="filter.date" @change="filterSearch($event)" />
          </div>
          <div class="trach-filter-form-field">
            <select v-model="filter.status" @change="filterSearch($event)">
              <option value="" selected>Status</option>
              <option v-for="data in status" :value="data.id" :key="data.name">
                {{ data.name }}
              </option>
            </select>
          </div>
          <div class="trach-filter-form-field">
            <select v-model="filter.isSort" @change="filterSearch($event)">
              <option value="" selected>Sort results by</option>
              <option v-for="data in task_sort" :value="data.val" :key="data.name">
                {{ data.name }}
              </option>
            </select>
          </div>


        </div>
        <div v-if="filter.is_filter == 1">
          Showing {{ this.result_length }} on {{ this.pages?.length }} page
        </div>
        <div class="col-md-12 page-dropdown">
          <select class="select-dropdown" v-model="pageSize" @change="pageChange($event)">
            <option value="50">50</option>
            <option value="30">30</option>
            <option value="20">20</option>
            <option value="10">10</option>
          </select>
        </div>
      </div>

      <div class="content-page">
        <div class="row">
          <div class="col-12">
            <div class="fullwidth-table track-table-body">
              <template v-for="group in groupedItems" :key="group.status">
                <h3 class="status-header">{{ group.status }}</h3>
                <!-- Display status header -->
                <table>
                  <tr>
                    <th>Date</th>
                    <th>Project</th>
                    <th>Task</th>
                    <th>Assign to</th>
                    <th>Deadline</th>
                    <th>Status</th>
                    <th>Time</th>
                    <th>Track</th>
                    <th>Details</th>
                  </tr>
                  <tr v-for="item in group.items" :key="item.id">
                    <td style="text-align: center">
                      {{ convertDate(item.date) }}
                    </td>
                    <td>{{ item.project_name }}</td>
                    <td style="text-align: center">
                      <textarea v-model="item.task" @change="updateTaskOnly(item.id, item.task)"
                        class="w-full border border-gray-300 rounded p-2" rows="1"></textarea>
                    </td>

                    <td style="text-align: center">
                      <select v-model="item.staff_id" style="
                          width: 100%;
                          padding: 8px;
                          border: 1px solid #ccc;
                          border-radius: 8px;
                        " @change="updateUserOnly(item.id, item.staff_id)">
                        <option v-if="!item.staff_id" disabled selected>
                          Select Staff
                        </option>
                        <!-- Show staff options based on item.project_id -->
                        <option v-for="staff in getStaffOptionsByProject(
          item.project_id
        )" :value="staff.value" :key="staff.value">
                          {{ staff.name }}
                        </option>
                      </select>
                    </td>

                    <td v-if="item.deadline == '1899-11-30T00:00:00.000Z'" style="text-align: center; width: 14%"></td>
                    <td v-else style="text-align: center; width: 14%">
                      {{ convertDate(item.deadline) }}
                    </td>
                    <td>
                      <select v-model="item.status" @change="updateStatus(item.id, item.status)"
                        class="w-full border border-gray-300 rounded p-2">
                        <option v-for="data in Astatus" :value="data.id" :key="data.name">
                          {{ data.name }}
                        </option>
                      </select>
                    </td>
                    <td style="text-align: center">
                      {{ item.tracked_time || "00:00:00" }}
                    </td>

                    <td style="text-align: center">
                      <!-- Start Timer Button -->
                      <a class="" @click="start(item)" v-if="activeTrackerId !== item.id" v-bind:style="{
          padding: '8px 12px',
          borderRadius: '4px',

          color: 'black',
          textDecoration: 'none',
          textAlign: 'center',
          display: 'inline-block',
          transition: 'background-color 0.3s',
          cursor: 'pointer',
        }">
                        <p>
                          <span class="staf-timing">
                            <img src="../../../assets/images/timer.png" />Tracker
                          </span>
                        </p>
                      </a>

                      <!-- Stop Timer Button -->
                      <a class="" @click="stop(item)" v-if="activeTrackerId === item.id " v-bind:style="{
                      padding: '8px 12px',
                      borderRadius: '4px',
                      // backgroundColor: '#f44336', /* Red */
                      color: 'black',
                      textDecoration: 'none',
                      textAlign: 'center',
                      display: 'inline-block',
                      transition: 'background-color 0.3s',
                      cursor: 'pointer',
                        }">
                        <p>
                          <span class="staf-timing">
                            <img src="../../../assets/images/timer.png" /><span style="font-weight: bold">{{
          formattedElapsedTime }}</span>
                          </span>
                        </p>
                      </a>
                    </td>
                    <td style="text-align: center">
                      <a @click="openTaskDetails(item)">
                        <img src="../../../assets/images/eye.png" alt="Edit" />
                      </a>
                    </td>
                  </tr>
                </table>
              </template>
            </div>
          </div>
        </div>
      </div>

      <!-- Right-side Panel for Task Details and Edit Form -->

      <div v-if="selectedTask" class="task-details-panel bg-white shadow-md rounded-lg max-w-lg mx-auto">
        <div style="height: 100vh; display: flex; flex-direction: column">
          <!-- Scrollable content section -->
          <div style="
              flex-grow: 1;
              overflow-y: auto;
            
              margin-bottom: 110px;
            ">
            <div style="padding: 20px;">
            <!-- Task title and close button -->
            <div style="display: flex; justify-content: space-between">
              <div>
                <!-- Show h2 text if not in edit mode -->
                <h2 v-if="!isEditing" @click="isEditing = true" class="text-2xl mb-4 text-blue-500 cursor-pointer">
                  {{ selectedTask.task || "Click to add description" }}
                </h2>

                <!-- Show textarea when in edit mode -->
                <textarea v-else v-model="selectedTask.task" rows="3" @blur="isEditing = false"
                  @change="updateTaskField('task', selectedTask.task)"
                  class="w-full text-area border-0 focus:border-blue-500 rounded p-2 text-blue-500 text-2xl focus:ring-0"></textarea>
              </div>
              <div class="flex space-x-4">
                <button @click="closeTaskDetails" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    background-color: #e0e0e0;
                    border: none;
                    border-radius: 8px;
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    transition: background-color 0.3s ease;
                  " onmouseover="this.style.backgroundColor='#b0b0b0';"
                  onmouseout="this.style.backgroundColor='#e0e0e0';">
                  <img src="../../../assets/images/dra.png" alt="Close" style="width: 20px; height: 20px" />
                </button>
              </div>
            </div>

            <!-- First Row: Assignee -->
            <div style="display: flex; gap: 10px">
              <div style="
                  display: flex;
                  flex-direction: column;
                  margin-top: 10px;
                  font-size: large;
                ">
                <label>Date:</label>
              </div>
              <input class="border-0" type="date" v-model="formattedDate" disabled />
            </div>

            <!-- Task Details -->
            <div style="
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-bottom: 16px;
              ">
              <!-- First Row: Assignee and Due Date -->
              <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  gap: 16px;
                ">
                <div style="flex: 1">
                  <label style="display: block; font-weight: 500; margin-bottom: 8px">Assignee:</label>
                  <select v-model="selectedTask.assignee" style="
                      width: 100%;
                      padding: 8px;
                      border: 1px solid #ccc;
                      border-radius: 8px;
                    " @change="updateTaskField('staff_id', selectedTask.assignee)">
                    <option v-for="staff in staffOptions" :value="staff.value" :key="staff.value">
                      {{ staff.name }}
                    </option>
                  </select>
                </div>
                <div style="flex: 1">
                  <label style="display: block; font-weight: 500; margin-bottom: 8px">Due Date:</label>
                  <input
                    type="date"
                    v-model="formattedDeadline"
                    @change="updateTaskField('deadline', $event.target.value)"
                    style="
                      width: 100%;
                      padding: 8px;
                      border: 1px solid #ccc;
                      border-radius: 8px;
                    "
                  />
                </div>
              </div>

              <!-- Second Row: Project Name and Status -->
              <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  gap: 16px;
                ">
                <div style="flex: 1">
                  <label style="display: block; font-weight: 500; margin-bottom: 8px">Project Name:</label>
                  <select v-model="selectedTask.project_id" style="
                      width: 100%;
                      padding: 8px;
                      border: 1px solid #ccc;
                      border-radius: 8px;
                    " @change="
          updateTaskField('project_id', selectedTask.project_id)
          ">
                    <option v-for="project in projectnames" :value="project.id" :key="project.id">
                      {{ project.project_name }}
                    </option>
                  </select>
                </div>
                <div style="flex: 1">
                  <label style="display: block; font-weight: 500; margin-bottom: 8px">Status:</label>
                  <select v-model="selectedTask.status" style="
                      width: 100%;
                      padding: 8px;
                      border: 1px solid #ccc;
                      border-radius: 8px;
                    " @change="updateTaskField('status', selectedTask.status)">
                    <option v-for="data in status" :value="data.id" :key="data.name">
                      {{ data.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Fourth Row: Description -->
            <div class="mb-4">
              <label class="block font-medium mb-1">Description:</label>
              <textarea v-model="selectedTask.description" rows="3" class="w-full border border-gray-300 rounded p-2"
                @change="
          updateTaskField('description', selectedTask.description)
          "></textarea>
            </div>
          </div>

            <!-- Fifth Row: Comments -->
            <div style="
                background-color: #f0f0f0 ;
                border-radius: 10px;
              
                padding: 10px;
              ">
              <!-- Display the list of comments fetched from the server -->
              <label class="comments-label">Comments:</label>
              <div class="">
  <!-- Check if there are comments -->
  <div v-if="selectedTask.commentsList && selectedTask.commentsList.length > 0">
    <div
      v-for="(comment, index) in selectedTask.commentsList"
      :key="index"
      class="comment-item"
    >
      <div class="comment-header" style="display: flex; align-items: center">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #007bff;
            color: white;
            font-weight: bold;
            margin-right: 10px;
            text-transform: uppercase;
          "
        >
          {{ comment.staff_name ? comment.staff_name.charAt(0) : "" }}
        </div>
        <div class="comment-info" style="flex-grow: 1">
          <span class="comment-name">{{ comment?.staff_name }}</span>
          <span class="comment-date">{{ formatDate(comment?.created_at) }}</span>
        </div>
        <div @click="toggleOptions(index)" class="dots" style="cursor: pointer">
          &#x022EE;
        </div>
        <div v-if="optionsVisible[index]" class="options" style="position: relative">
          <div
            style="
              background: white;
              border: 1px solid #ccc;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
              position: absolute;
              right: 0;
              z-index: 10;
            "
          >
            <div @click="startEditing(index, comment.comment)" style="padding: 8px; cursor: pointer">
              Edit
            </div>
            <div @click="deleteComment(comment.id)" style="padding: 8px; cursor: pointer">
              Delete
            </div>
          </div>
        </div>
      </div>

      <div class="comment-text" v-if="!isEdit[index]">
        <p>{{ comment.comment }}</p>

        <div v-if="comment.files.length > 0" style="margin-top: 10px">
          <div v-for="(file, fileIndex) in comment.files" :key="fileIndex">
            <img
              v-if="isImage(file.file_path)"
              :src="file.file_path"
              alt="Uploaded image"
              style="max-width: 100%; border-radius: 8px; margin-top: 5px"
            />
            <a
              v-else
              :href="file.file_path"
              :download="file.file_name"
              target="_blank"
              style="display: inline-block; margin-top: 5px"
            >
              {{ file.file_name }}
            </a>
          </div>
        </div>
      </div>

      <div v-if="isEdit[index]">
        <textarea
          v-model="editedComment[index]"
          class="comment-textarea"
          rows="2"
          placeholder="Edit your comment..."
        ></textarea>
        <div style="display: flex; justify-content: flex-end; gap: 10px">
          <button
            @click="cancelEdit(index)"
            style="
              padding: 8px 16px;
              cursor: pointer;
              background-color: #6c757d;
              color: white;
              border-radius: 12px;
              border: none;
              text-align: center;
            "
          >
            Cancel
          </button>
          <button
            @click="saveEdit(index, comment.id)"
            style="
              padding: 8px 16px;
              cursor: pointer;
              background-color: #007bff;
              color: white;
              border-radius: 12px;
              border: none;
              text-align: center;
            "
          >
            Save
          </button>
        </div>
      </div>
      <hr class="comment-divider" />
    </div>
  </div>

  <!-- Display "No comments yet" if there are no comments -->
  <div v-else style="text-align: center;color: #888; padding: 10px">
    No comments yet
  </div>
</div>

            </div>
          </div>
        </div>

        <!-- Comment input area -->
        <!-- Fixed comment input area at the bottom -->
        <div style="
            position: sticky;
            bottom: 0;
            background-color: white;
            padding: 10px;
            box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
          ">
          <div style="display: flex; position: relative">
            <!-- Circle for User's Initial -->
            <div style="
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: #007bff;
                color: white;
                font-weight: bold;
                text-transform: uppercase;
                margin-right: 10px;
              ">
              {{ this.$store.state.user.name ? this.$store.state.user.name.charAt(0) : '' }}
            </div>

            <!-- Textarea for adding a comment -->
            <textarea v-model="selectedTask.comments" rows="4" placeholder="Add a comment" class="comment-textarea"
              @focus="isCommentFocused = true" @blur="handleBlur" style="
                flex-grow: 1;
                padding: 10px;
                border-radius: 8px;
                border: 1px solid #ccc;
                position: relative;
              ">
      </textarea>

            <!-- File upload icon placed inside textarea -->
            <div style="position: absolute; bottom: 30px; left: 10px">
              <!-- Using custom SVG for paperclip icon -->
              <label for="fileUpload" style="cursor: pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                  stroke="#007bff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-paperclip">
                  <path
                    d="M21.44 11.05L12 20.49a5 5 0 0 1-7.07-7.07L13.48 4.86a3.5 3.5 0 0 1 4.95 4.95L10 18.24a2 2 0 1 1-2.83-2.83L16.12 6.46" />
                </svg>
              </label>
              <input type="file" id="fileUpload" @change="handleFileUpload" multiple style="display: none" />
            </div>
          </div>

          <!-- Display selected files with option to remove -->
          <div v-if="uploadedFiles?.length > 0" style="margin-top: 10px">
            <ul>
              <li v-for="(file, index) in uploadedFiles" :key="index">
                {{ file.name }}
                <button @click="removeFile(index)" style="margin-left: 10px; color: red; background-color: white">
                  Remove
                </button>
              </li>
            </ul>
          </div>
          <div v-if="isCommentFocused" style="display: flex; justify-content: end; margin-bottom: 30px"
            class="submit-button-container">
            <button @click="updateComment" class="submit-button">
              Comment
            </button>
          </div>
        </div>
      </div>
      <CommonFooter />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LeftPannel from "../../../components/user/LeftPannel.vue";
import startTracking from "../../../components/user/startTracking.vue";
import stopTracking from "../../../components/user/stopTracking.vue";
import CommonFooter from "../../../components/CommonFooter.vue";
import base_url from "../../../base_url.js";
import moment from "moment";

const $ = require("jquery");
window.$ = $;

export default {
  components: {
    LeftPannel,
    stopTracking,
    startTracking,
    CommonFooter,
  },
  data() {
    return {
      data: {
        is_checkin: "false",
        is_checkout: "false",
        checkin: "",
        checkout: "",
        hours: "",
        date: new Date().toISOString().substr(0, 10),
        isCheckout: "No",
      },
      activeTrackerId: null,
      startTracker: true,
      stopTracker: false,
      showstartTracker: false,
      showstopTracker: false,
      projectid: "",
      project_name: "",
      projectnames: [],
      task: "",
      end: "",
      timer: undefined,
      starttime: 0,
      now_time: 0,
      temptime: 0,
      totalSeconds: 0,
      timerbeforecheckout: true,
      checkoutyes: "",
      matches: "",

      ///////
trackId: null,
      loading: true,
      isEditing: false,
      showTrackingModal: false,
      totalMilliseconds: 0,
      selectedTask: null,
      options: [],
      errors: {},
      value: [],
      // projectnames: [],
      isEdit: [], // Track which comments are being edited
      editedComment: [],
      optionsVisible: [],
      items: [],
      isCommentFocused: false,
      page: 1,
      filter_page: 1,
      result_length: "",
      perPage: 50,
      pageSize: 50,
      pages: [],
      comments: "",
      newComment: "", // For adding a new comment
      visibleComments: [], // This will handle showing the first 3 comments
      commentsPerPage: 3,
      uploadedFiles: [],
      startTimeofTrack:"",
      TotalTrackTime:"",

      status: [
        { id: 0, name: "Pending" },
        { id: 1, name: "Working" },
        { id: 2, name: "Review" },
        { id: 3, name: "Completed" },
      ],
      Astatus: [
        { id: 0, name: "Pending" },
        { id: 1, name: "Working" },
        { id: 2, name: "Review" },
        { id: 3, name: "Completed" },
      ],
      task_sort: [
        { val: "dt_desc", name: "By Date Desc" },
        { val: "dd_desc", name: "By Deadline Desc" },
        { val: "dt_asc", name: "By Date Asc" },
        { val: "dd_asc", name: "By Deadline Asc" },
      ],
      obj: {
        task: null,
        project_id: null,
      },
      filter: {
        name: "",
        project: "",
        date: "",
        status: "",
        isSort: "",
        is_filter: 0,
        archive: 0,
      },
    };
  },
  mounted() {
    this.getProjectName();


    this.getStaffArr();



  },
  computed: {
    formattedElapsedTime() {
      const sec = parseInt(this.totalSeconds, 10);
      let hours = Math.floor(sec / 3600);
      let minutes = Math.floor((sec - hours * 3600) / 60);
      let seconds = sec - hours * 3600 - minutes * 60;
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return hours + ":" + minutes + ":" + seconds;
    },

    groupedItems() {
      const statusOrder = ["Pending", "Working", "Review", "Completed"];
      const grouped = this.items.reduce((groups, item) => {
        const statusName = this.status.find(
          (status) => status.id === item.status
        )?.name;
        if (!groups[statusName]) {
          groups[statusName] = [];
        }
        groups[statusName].push(item);
        return groups;
      }, {});

      // Return the items grouped by status in the correct order
      return statusOrder.map((status) => ({
        status,
        items: grouped[status] || [],
      }));
    },

    staffOptions() {
      // Return options or default to an empty array if not yet loaded
      return this.options?.length > 0 ? this.options : [];
    },
    // Deadline formatted as YYYY-MM-DD
    formattedDeadline: {
  get() {
    // Simply return the selectedTask.deadline date in YYYY-MM-DD format without any adjustments
    return new Date(this.selectedTask.deadline).toISOString().split("T")[0];
  },
  set(value) {
    // Directly store the selected date without adjustments
    this.selectedTask.deadline = value;
  }
},



    // Date formatted as YYYY-MM-DD
    formattedDate: {
      get() {
        const date = new Date(this.selectedTask.date);
        return date.toISOString().split("T")[0]; // Return YYYY-MM-DD
      },
      set(value) {
        this.selectedTask.date = value; // Update date on input change
      },
    },
  },
  created() {
    if (this.$store.state.user?.length != 0) {
      this.getProjectName();
      if (localStorage.getItem("StaffTaskFilter")) {
        var name = JSON.parse(localStorage.getItem("StaffTaskFilter"))["name"];
        var project = JSON.parse(localStorage.getItem("StaffTaskFilter"))[
          "project"
        ];
        var date = JSON.parse(localStorage.getItem("StaffTaskFilter"))["date"];
        var status = JSON.parse(localStorage.getItem("StaffTaskFilter"))[
          "status"
        ];
        var isSort = JSON.parse(localStorage.getItem("StaffTaskFilter"))[
          "isSort"
        ];
        var archive = JSON.parse(localStorage.getItem("StaffTaskFilter"))[
          "archive"
        ];
        this.filter.name = name;
        this.filter.project = project;
        this.filter.date = date;
        this.filter.status = status;
        this.filter.isSort = isSort;
        this.filter.archive = archive;
        this.filterSfilterSearch();
      } else {
        this.showTask();
      }
    } else {
      axios
        .post(`${base_url}/get_staff`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          this.$store.state.user = res.data.user;
          this.getProjectName();
          if (localStorage.getItem("StaffTaskFilter")) {
            var name = JSON.parse(localStorage.getItem("StaffTaskFilter"))[
              "name"
            ];
            var project = JSON.parse(localStorage.getItem("StaffTaskFilter"))[
              "project"
            ];
            var date = JSON.parse(localStorage.getItem("StaffTaskFilter"))[
              "date"
            ];
            var status = JSON.parse(localStorage.getItem("StaffTaskFilter"))[
              "status"
            ];
            var isSort = JSON.parse(localStorage.getItem("StaffTaskFilter"))[
              "isSort"
            ];
            var archive = JSON.parse(localStorage.getItem("StaffTaskFilter"))[
              "archive"
            ];
            this.filter.name = name;
            this.filter.project = project;
            this.filter.date = date;
            this.filter.status = status;
            this.filter.isSort = isSort;
            this.filter.archive = archive;
            this.filterSearch();
          } else {
            this.showTask();
          }
        });
    }
    axios
      .post(`${base_url}/get_staff`, {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.$store.state.user = res.data.user;
        this.matches = this.$store.state.user.name.match(/\b(\w)/g).join("");
        this.checkBox(), this.checkTime();
      });
  },
  watch: {
    items() {
      this.setPages();
    },
  },
  methods: {
    isImage(filePath) {
      const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
      const fileExtension = filePath.split(".").pop().toLowerCase();
      return imageExtensions.includes(fileExtension);
    },
    startTimer() {
      // Clear any existing timer
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      // Start the interval to update totalSeconds every second
      this.totalSeconds = 0; // Reset totalSeconds for the new task
      this.intervalId = setInterval(() => {
        this.totalSeconds++;
      }, 1000); // Update every 1 second
    },

    // Add a method to stop the timer if needed
    stopTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null; // Reset the interval ID
      }
    },

    //  new traking

    startTracking() {
      this.projectid = "";
      this.task = "";
      this.showstartTracker = true;
      this.getProjectName();
    },
    async stopTracking() {
      $(".staff-timer-div").attr("href", "#popup2");
      this.getProjectName();
      try {
        const response = await axios.post(`${base_url}/getlasttrack`, {
          track_id:this.trackId?this.trackId:"",
          staffid: this.$store.state.user.staffid,
        });
        console.log(response.data,"response of get last track");
        this.TotalTrackTime=response.data.time
        this.startTimeofTrack=response.data.start
        this.task = response.data.task;
        this.projectid = response.data.projectid;
      } catch (err) {
        console.log(err);
      }
      this.showstopTracker = true;
    },
    async start(item) {
      
      if (this.data.is_checkin == "false" && this.data.is_checkout == "false") {
        alert("Checkin is required");
        return;
      }
      // Check if there is an active tracker
      if (this.activeTrackerId !== null && this.activeTrackerId !== item.id) {
        // Alert the user to stop the previous task tracker first
        alert(
          "Please stop the current task tracker before starting a new one."
        );
        return;
      }

      // Proceed with starting the new task tracker
      await axios
        .post(`${base_url}/addstafftrack`, {
          track_id:item.track_id,
          staffid: this.$store.state.user.staffid,
          projectid:
            item.project_id === undefined ? this.projectid : item.project_id,
          date: new Date().toISOString().substr(0, 10),
          start:
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          end: item.end === undefined ? this.end : item.end,
          task: item.task === undefined ? this.task : item.task,
          task_id: item.id || null,
          time: "00:00:00",
          admin_id: this.$store.state.user.id,
        })
        .then(() => {
          console.log();
          this.showTask()
          this.trackId=item.track_id
          this.showstartTracker = false;
          this.startTracker = false;
          this.stopTracker = true;
          this.activeTrackerId = item.id;
          this.startTimer(item);
         
        

        });
        
    },

    async stop(item) {
  console.log(item, "item.....");

  // Ask for confirmation
  const confirmation = confirm("Are you sure you want to stop the tracker for this task?");
  if (!confirmation) {
    console.log("Tracker stop canceled by user.");
    return;
  }

  // Current end time
  const endTime = moment();
  
  // Use `item.track_start` or fallback to `startTimeofTrack` if undefined
  const startTrackTime = item.track_start || this.startTimeofTrack;
  const timeCondition = moment(startTrackTime, "HH:mm:ss");  // Ensure both are parsed in HH:mm:ss format
  console.log(timeCondition.format("HH:mm:ss"), "timeCondition");

  // Calculate time difference
  const timeDiff = moment.duration(endTime.diff(timeCondition));
  console.log(timeDiff, "timeDiff");
  console.log(this.TotalTrackTime,"TotalTrackTime");

  const timeDiffCondition=item.tracked_time===undefined?this.TotalTrackTime:item.tracked_time

  // Add this time difference to the existing tracked time
  const previousTrackedTime = moment.duration(timeDiffCondition);
  const totalDuration = previousTrackedTime.add(timeDiff);

  // Convert total duration to HH:mm:ss format
  const totalTime = `${totalDuration.hours().toString().padStart(2, "0")}:${totalDuration.minutes().toString().padStart(2, "0")}:${totalDuration.seconds().toString().padStart(2, "0")}`;
  console.log(totalTime, "totalTime");

  // Prepare data for backend
  const requestData = {
    track_id: (item?.track_id||this.trackId) || "",
    staffid: this.$store.state.user.staffid,
    projectid: item.project_id === undefined ? this.projectid : item.project_id,
    date: new Date().toISOString().substr(0, 10),
    start: this.starttime,
    startDate: this.$store.state.checktime.date,
    end: new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds(),
    end1: new Date().toISOString().substr(0, 10) + " " + new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds(),
    task: item.task === undefined ? this.task : item.task,
    admin_id: this.$store.state.user.id,
    totalTime: totalTime, // new total tracked time
  };
  console.log(requestData, "requestData");

  try {
    await axios.post(`${base_url}/lasttrackupdate/${this.$store.state.user.staffid}`, requestData);
    this.showTask();

    // Update tracker states
    this.showstopTracker = false;
    this.stopTracker = false;
    this.startTracker = true;
    this.activeTrackerId = null;
 this.$router.go()

    console.log("Tracker stopped successfully.");
  } catch (error) {
    console.error("Error stopping tracker:", error);
  }
},

    closepopup() {
      this.showstartTracker = false;
      this.showstopTracker = false;
    },
    timerstarting() {
      if (this.data.is_checkin == "false" && this.data.is_checkout == "false") {
        alert("Checkin is required");
      }
      if (this.data.is_checkin == "true" && this.data.is_checkout == "false") {
        $("a").attr("href", "#popup1");
        this.startTracking();
      }
      if (this.checkoutyes == "Yes") {
        alert("Checkout done");
      }
    },
    async getProjectName() {
      try {
        const response = await axios.get(
          `${base_url}/staffassignedprojects/${this.$store.state.user.staffid}`
        );

        this.projectnames = response.data;

      } catch (err) {
        console.log(err);
      }
    },

    //check box value control
    async checkBox() {
      if (this.$store.state.checkbox?.length != 0) {
        this.checkoutyes = this.$store.state.checkbox.isCheckout;
        if (this.$store.state.checkbox.isCheckout == "Yes") {
          this.data.is_checkin = "true";
          this.data.is_checkout = "true";
        } else if (
          this.$store.state.checkbox.isCheckout == "No" &&
          this.$store.state.checkbox.date !== ""
        ) {
          this.data.is_checkin = "true";
        } else {
          this.data.is_checkin = "false";
          this.data.is_checkout = "false";
        }
      } else {
        await axios
          .post(`${base_url}/checkbox`, {
            date: this.data.date,
            staffid: this.$store.state.user.staffid,
          })
          .then((res) => {
            this.$store.state.checkbox = res.data[0];
            this.checkoutyes = this.$store.state.checkbox.isCheckout;
            if (this.$store.state.checkbox.isCheckout == "Yes") {
              this.data.is_checkin = "true";
              this.data.is_checkout = "true";
            } else if (
              this.$store.state.checkbox.isCheckout == "No" &&
              this.$store.state.checkbox.date !== ""
            ) {
              this.data.is_checkin = "true";
            } else {
              this.data.is_checkin = "false";
              this.data.is_checkout = "false";
            }
          });
      }
    },
    //staff check in
    async checkIn() {
      var curr_time = new Date();
      const time =
        curr_time.getHours() +
        ":" +
        curr_time.getMinutes() +
        ":" +
        curr_time.getSeconds();
      this.data.checkin = time;
      this.data.is_checkin = "true";
      await axios
        .post(`${base_url}/checkin`, {
          date: this.data.date,
          staffid: this.$store.state.user.staffid,
          admin_id: this.$store.state.user.id,
          checkin: this.data.checkin,
          isCheckout: this.data.isCheckout,
        })
        .then((res) => {
          if (res.data == "Allready Checkin") {
            alert("Allready Checkin");
            this.data.is_checkin = "true";
          } else {
            this.data.is_checkin = "true";
            this.$router.go();
          }
        });
    },
    //staff checkout
    async checkOut() {
      if (this.data.checkin == "" && this.data.is_checkin == "") {
        alert("Checked In is Required");
        this.data.is_checkin = "";
        this.data.is_checkout = "false";
      }
      if (this.data.hours == "" && this.data.is_checkin == "false") {
        alert("Checked In is Required");
        this.data.is_checkin = "";
        this.data.is_checkout = "false";
      }
      if (this.timerbeforecheckout == false) {
        alert("Close the Tracker first");
        this.data.is_checkin = "true";
        this.data.is_checkout = "false";
      }
      if (this.data.is_checkin == "true" && this.timerbeforecheckout == true) {
        var curr_time = new Date();
        const time =
          curr_time.getHours() +
          ":" +
          curr_time.getMinutes() +
          ":" +
          curr_time.getSeconds();
        this.data.checkout = time;
        this.data.isCheckout = "Yes";
        await axios
          .post(`${base_url}/checkout`, {
            date: this.data.date,
            staffid: this.$store.state.user.staffid,
            checkin: this.data.checkin,
            checkout: this.data.checkout,
            isCheckout: this.data.isCheckout,
          })
          .then((res) => {
            if (res.data == "Allready Checkout") {
              alert("Allready Checkout");
              this.data.is_checkin = "true";
              this.data.is_checkout = "true";
            } else {
              this.data.is_checkout = "true";
              this.$router.go();
            }
          });
      }
    },
    async checkTime() {
      if (this.$store.state.checktime?.length != 0) {
        if (this.$store.state.checktime.end == "00:00:00") {
          this.stopTracking();
          this.timerbeforecheckout = false;
          this.stopTracker = true;
          this.startTracker = false;
          this.starttime = this.$store.state.checktime.start;
          this.now_time = (
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds()
          ).toString();
          var now_time = moment(this.now_time, "HH:mm:ss");
          var starttime = moment(this.starttime, "HH:mm:ss");
          var hrs = moment.utc(now_time.diff(starttime)).format("HH");
          var min = moment.utc(now_time.diff(starttime)).format("mm");
          var sec = moment.utc(now_time.diff(starttime)).format("ss");
          this.temptime = [hrs, min, sec].join(":");
          const hms = this.temptime;
          const [hr, mt, s] = hms.split(":");
          this.totalSeconds = +hr * 60 * 60 + +mt * 60 + +s;
          setInterval(() => {
            this.totalSeconds += 1;
          }, 1000);
        } else {
          this.startTracker = true;
        }
      } else {
        await axios
          .post(`${base_url}/checktime`, {
            staffid: this.$store.state.user.staffid,
          })
          .then((res) => {

            this.$store.state.checktime = res.data[0];
            if (this.$store.state.checktime.end == "00:00:00") {
              this.stopTracking();
              this.timerbeforecheckout = false;
              this.stopTracker = true;
              this.startTracker = false;
              this.starttime = this.$store.state.checktime.start;
              this.now_time = (
                new Date().getHours() +
                ":" +
                new Date().getMinutes() +
                ":" +
                new Date().getSeconds()
              ).toString();
              var now_time = moment(this.now_time, "HH:mm:ss");
              var starttime = moment(this.starttime, "HH:mm:ss");
              var hrs = moment.utc(now_time.diff(starttime)).format("HH");
              var min = moment.utc(now_time.diff(starttime)).format("mm");
              var sec = moment.utc(now_time.diff(starttime)).format("ss");
              this.temptime = [hrs, min, sec].join(":");
              const hms = this.temptime;
              const [hr, mt, s] = hms.split(":");
              this.totalSeconds = +hr * 60 * 60 + +mt * 60 + +s;
              setInterval(() => {
                this.totalSeconds += 1;
              }, 1000);
            } else {
              this.startTracker = true;
            }
          });
      }
    },

    //////////////////////////////////////////////////////////////////////////////////////////////

    getStaffOptionsByProject(projectId) {
      const project = this.staffArr.find((p) => p.project_id === projectId);

      return project ? project.staffs : [];
    },

    toggleOptions(index) {
      // Toggle visibility of the options for the clicked comment
      this.optionsVisible[index] = !this.optionsVisible[index];
    },
    startEditing(index, commentText) {
      // Start editing the selected comment
      this.isEdit[index] = true; // Mark this comment as being edited
      this.editedComment[index] = commentText; // Pre-fill with the current comment text
    },
    saveEdit(index, commentId) {
      const updatedComment = this.editedComment[index];


      axios
        .put(`${base_url}/comments/${commentId}`, { comment: updatedComment })
        .then(() => {


          this.selectedTask.commentsList[index].comment = updatedComment;
          this.isEdit[index] = false;
        })
        .catch((error) => {
          console.error("Error updating comment:", error);
        });

      // For now, let's just mark editing as done
      this.isEdit[index] = false; // Exit edit mode
      // Reset the options visibility after editing
      this.optionsVisible[index] = false;
    },
    cancelEdit(index) {
      // Exit edit mode for this comment
      this.isEdit[index] = false;
    },

    async deleteComment(id) {


      try {
        await axios.put(`${base_url}/delete_comment/${id}`);

        this.showTask();
        this.fetchTaskComments(this.selectedTask.id);

        // Notify the user of success
        this.$notify({
          title: "<b>Comment</b> deleted successfully!",
          type: "success",
        });
      } catch (error) {
        console.error("Error deleting comment:", error);
      }
    },
    handleFileUpload(event) {
      this.uploadedFiles = Array.from(event.target.files);
    },
    removeFile(index) {
      this.uploadedFiles.splice(index, 1);
    },

    async updateComment() {
      if (!this.selectedTask.comments) {
        this.$notify({
          title: "<b>Error</b>: Comment cannot be empty!",
          type: "error",
        });
        return;
      }

      try {
        const staff_id = localStorage.getItem("staff_id");
        const formData = new FormData();

        // Append task and comment data to FormData
        formData.append("task_id", this.selectedTask.id);
        formData.append("staff_id", staff_id);
        formData.append("comment", this.selectedTask.comments);
        formData.append("date", new Date().toISOString());

        // Append uploaded files to FormData
        if (this.uploadedFiles && this.uploadedFiles?.length > 0) {
          this.uploadedFiles.forEach((file) => {
            formData.append("files[]", file); // Append each file with 'files[]'
          });
        }



        // Send the formData via POST request
        await axios.post(`${base_url}/insertcomments`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // Clear the comment input and file selection
        this.selectedTask.comments = ""; // Clear input field
        this.uploadedFiles = []; // Clear uploaded files
        this.fetchTaskComments(this.selectedTask.id); // Refresh comments list

        // Show success notification
        this.$notify({
          title: "<b>Comment</b> added successfully!",
          type: "success",
        });
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    },

    handleBlur() {
      setTimeout(() => {
        this.isCommentFocused = false;
      }, 100);
    },

    async myFunction(chosen) {
      try {
        const response = await axios.get(`${base_url}/projects/${chosen}`);
        if (response.data.staff) {
          this.value = JSON.parse(response.data.staff);
        } else {
          this.value = [];
        }
        this.id = chosen;
        this.temp = this.value;
      } catch (error) {
        console.error("Error fetching project staff:", error);
        this.errors.value = "Failed to load staff data."; // Handle errors
      }
    },
    //for accordian
    async getStaff(projectId) {
      // Guard clause to prevent API call if projectId is undefined
      if (!projectId) {
        console.warn("Project ID is missing; skipping getStaff call.");
        return;
      }

      try {
        const response = await axios.get(
          `${base_url}/projectstaff/${this.$store.state.user.id}/${projectId}`
        );
        this.options = response.data;

      } catch (error) {
        console.error("Error fetching staff data:", error);
      }
    }
    ,
    //for normal
    async getStaffArr() {
      try {
        const response = await axios.get(`${base_url}/get_s_list`);
        this.staffArr = response.data; // Set the fetched data to options

      } catch (error) {
        console.error("Error fetching staff data:", error);
      }
    },
    async updateStatus(taskId, statusId) {

      try {
         await axios.put(`${base_url}/changestatus/${taskId}`, {
          statusId,
        });


        // Show notification
        this.$notify({
          title: "<b>Status</b> updated successfully!",
          type: "warn",
        });

        // Optionally refresh the task list if necessary
        // await this.fetchTasks();  // Uncomment if you want to refetch tasks from backend
      } catch (error) {
        console.error("Error updating status:", error);
        this.$notify({
          title: "<b>Error</b> updating status!",
          type: "error",
        });
      }
    },

    async openTaskDetails(task) {
      this.selectedTask = { ...task };


      // Set a default project_id if it's not defined
      if (this.filter.project && !this.selectedTask.project_id) {
        this.selectedTask.project_id = this.filter.project;
      } else if (
        !this.selectedTask.project_id &&
        this.projectnames?.length > 0
      ) {
        this.selectedTask.project_id = this.projectnames[0].project_id;
      }

      // Call getStaff only if project_id is available
      if (this.selectedTask.project_id) {
        await this.getStaff(this.selectedTask.project_id);
      } else {
        console.warn("No project ID available; skipping getStaff call.");
      }

      this.selectedTask.assignee = this.selectedTask.staff_id;


      await this.fetchTaskComments(this.selectedTask.id);
    }
    ,
    async fetchTaskComments(taskId) {
      try {
        const role = localStorage.getItem("type");


        const response = await axios.get(
          `${base_url}/get_comments/${taskId}?role=${role}`
        );


        // Transform file paths to full URLs without the "uploads" segment
        this.selectedTask.commentsList = response.data.map((comment) => {
          // Check if files exist and create full URLs
          if (comment.files && comment.files?.length > 0) {
            comment.files = comment.files.map((file) => {
              // Remove the '/uploads' part from the path and construct the full URL
              const pathWithoutUploads = file.file_path.replace("/uploads", "");
              file.file_path = `${base_url}/static${pathWithoutUploads}`;
              return file;
            });
          }
          return comment;
        });

        // Initially show only up to 3 comments
        this.visibleComments = this.selectedTask.commentsList.slice(0, 3);

      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    },

    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    showMoreComments() {
      const currentLength = this.visibleComments?.length;
      const nextComments = this.selectedTask.commentsList.slice(
        currentLength,
        currentLength + 3
      );

      // Add the next 3 comments (or less if fewer are available)
      this.visibleComments.push(...nextComments);
    },
    closeTaskDetails() {
      this.selectedTask = null;
      this.errors = {}; // Clear the errors
    },
    getStatusLabel(status) {
      switch (status) {
        case 0:
          return "Pending";
        case 1:
          return "Working";
        case 2:
          return "Review";
        case 3:
          return "Completed";
        case 4:
          return "Archived";
        default:
          return "Unknown";
      }
    },
    async updateTaskField(field, value) {
      this.errors = [];

      // Format deadline if necessary
      if (field === "deadline") {
        value = this.convertDate(value); // Ensure the date is formatted correctly
      }



      try {
        // Send only the changed field and its value
        await axios.post(`${base_url}/task/${this.selectedTask.id}`, {
          [field]: value, // Only the field being updated
          staff_id: this.selectedTask.assignee, // Ensure this is still sent if needed
        });
        this.showTask();

        // Notify the user of success
        this.$notify({
          title: "<b>Task</b> updated successfully!",
          type: "warn",
        });
      } catch (error) {
        console.error(error);
      }
    },
    async updateTaskOnly(taskId, taskValue) {
      this.errors = [];



      try {
        // Send only the task field and its value
        await axios.post(`${base_url}/task/${taskId}`, {
          task: taskValue, // Only the task being updated
        });

        // Optionally refresh the task data
        this.showTask();

        // Notify the user of success
        this.$notify({
          title: "<b>Task</b> updated successfully!",
          type: "success",
        });
      } catch (error) {
        console.error("Error updating task:", error);
      }
    },
    async updateUserOnly(taskId, user) {
      this.errors = [];



      try {
        // Send only the task field and its value
        await axios.post(`${base_url}/task/${taskId}`, {
          staff_id: user, // Only the task being updated
        });

        // Optionally refresh the task data
        this.showTask();

        // Notify the user of success
        this.$notify({
          title: "<b>Task</b> updated successfully!",
          type: "success",
        });
      } catch (error) {
        console.error("Error updating task:", error);
      }
    },

    convertDate: function (cvt_date) {
      var date = new Date(cvt_date);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();
      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      const cvt_date_formate = year + "-" + month + "-" + dt;
      return cvt_date_formate;
    },

    async filterSearch() {
      var filter = {
        name: this.filter.name,
        project: this.filter.project,
        date: this.filter.date,
        status: this.filter.status,
        isSort: this.filter.isSort,
        archive: this.filter.archive,
      };

      localStorage.setItem("TaskFilter", [JSON.stringify(filter)]);
      const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      await axios
        .post(`${base_url}/task_stafffilter` + params, {
          name: this.filter.name,
          project: this.filter.project,
          date: this.filter.date,
          status: this.filter.status,
          isSort: this.filter.isSort,
          staff_id: localStorage.getItem("staff_id"),
          archive: this.filter.archive,
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.results;
          this.page = res.data.page;
          this.result_length = res.data?.length?.length;
        });
      if (this.filter.name) {
        this.filter.is_filter = 1;
      }
    },
    async showTask() {
      const params = "?pages=" + this.page + "&size=" + this.pageSize;
      await axios
        .post(`${base_url}/get_stafftask` + params, {
          staff_id: this.$store.state.user.staffid,
        })
        .then((response) => {
          if (response.data.error) {
            localStorage.clear();
            this.$store.state.user = "";
            this.$router.go();
            // this.$router.push('/login');
          } else {
            this.loading = false;
            this.items = response.data.results;
            this.page = response.data.page;
            this.result_length = response.data?.length?.length;
            this.filter.is_filter = 0;
            this.filter.name = "";
            this.filter.project = "";
            this.filter.date = "";
            this.filter.status = "";
            this.filter.isSort = "";
            this.filter.archive = "";
            localStorage.removeItem("StaffTaskFilter");
          }
        });
    },
    async showArchiveTask() {
      this.filter.name =
        this.filter.project =
        this.filter.date =
        this.filter.status =
        this.filter.isSort =
        "";
      if (!this.filter.archive) {
        this.filter.archive = 1;
      } else {
        this.filter.archive = 0;
      }
      this.filterSearch();
    },

    setPages() {
      this.pages = [];
      this.perPage = this.pageSize;
      let numberOfPages = Math.ceil(this.result_length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    //page number wise display
    pageCount(pageNumber) {
      $(window).scrollTop(0);
      if (!pageNumber) {
        this.page++;
      }
      this.page = pageNumber;
      this.showTask();
    },
    //filter page number
    pageCounts(pageNumber) {
      if (!pageNumber) {
        this.filter_page++;
      }
      this.filter_page = pageNumber;
      this.filterSearch();
    },
    //display prev page
    prevPage() {
      $(window).scrollTop(0);
      this.page--;
      this.showTask();
    },
    //filter prev page
    prevPages() {
      this.filter_page--;
      this.filterSearch();
    },
    //display next page
    nextPage() {
      $(window).scrollTop(0);
      this.page++;
      this.showTask();
    },
    nextPages() {
      this.filter_page++;
      this.filterSearch();
    },
    //per page change
    pageChange() {
      this.filter_page = 1;
      this.page = 1;
      if (this.filter.is_filter == 1) {
        this.filterSearch();
      } else {
        this.showTask();
      }
    },
  },
};
</script>
